export const GET_CERTIFICATE = 'GET_CERTIFICATE'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const MAIN_LOGIN_DATA = 'MAIN_LOGIN_DATA'
export const MAIN_LOGIN_DATA_ERR = "MAIN_LOGIN_DATA_ERR"
export const GET_ALL_EDUCERT = 'GET_ALL_EDUCERT'
export const GET_ALL_SEVCERT = 'GET_ALL_SEVCERT'
export const GET_CERTIFICATE_XML = 'GET_CERTIFICATE_XML'
export const GET_BULK_PULL = 'GET_BULK_PULL'
export const GET_BULK_PULL_ERR = 'GET_BULK_PULL_ERR'
export const OTP_VERIFY = 'OTP_VERIFY'
export const OTP_VERIFY_ERR = 'OTP_VERIFY_ERR'
export const GET_DIGI_LOCKER = 'GET_DIGI_LOCKER'
export const GET_DIGI_LOCKER_ERR = 'GET_DIGI_LOCKER_ERR'
export const LOGOUT_DATA_ERR ="LOGOUT_DATA_ERR"
export const LOGOUT_DATA = "LOGOUT_DATA"
export const GET_CERTIFICATE_ERR = "GET_CERTIFICATE_ERR"
export const RESEND_OTP_ERR = "RESEND_OTP_ERR"
export const RESEND_OTP = "RESEND_OTP"
export const VERIFY_OTP = "VERIFY_OTP"
export const GENERATE_OTP = "GENERATE_OTP"
export const SET_ROUTING_NAME = "SET_ROUTING_NAME" 
export const BLOCK_USER = "BLOCK_USER"
export const BLOCK_USER_ERR = "BLOCK_USER_ERR"
export const GET_TOTAL_USER_COUNT = "GET_TOTAL_USER_COUNT"
export const GET_ALL_BLOCKED_USERS = "GET_ALL_BLOCKED_USERS"
export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_LOGIN_CONSENT = "GET_LOGIN_CONSENT"
export const GET_ALL_DMECERT = "GET_ALL_DMECERT"
export const GENERATE_OTP_ERR = "GENERATE_OTP_ERR"
export const GET_REVOKE_USERS = "GET_REVOKE_USERS"
export const GET_ALL_ZONE = "GET_ALL_ZONE"
export const GET_ALL_DISTRICT = "GET_ALL_DISTRICT"
export const GET_ALL_SRO = "GET_ALL_SRO"