import React from 'react'

function Card2({ children }) {
  return (
    <div className="flex flex-col w-full p-8 m-12 border rounded-lg " id="cardDataEsevaicard" >
      {children}
    </div>
  )
}

export default Card2
