import React from 'react'

function Card4({ children }) {
  return (
    <div className="flex flex-col w-full p-8 m-12 border rounded-lg " id="cardDataDiploma1" >
      {children}
    </div>
  )
}

export default Card4
