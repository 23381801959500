import React from "react";
import { createRoot } from 'react-dom/client';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          "submit": "Submit",
          "share": "Share Now",  
          "add": "Secure Your Data on Nambikkai Inaiyam",   
          "fetch": "Fetch Your Certificates \nUsing e-Pettagam.",
          "service": "Our Services",
          "service1": "Services",
          "education": "Educational Certificates",
          "govt": "E-Sevai Certificates`",
          "sync": "Sync Now with Digi-Locker",
          "esevai": "Select Your Tamil Nadu Certificates",
          "select": "Select Your Certificates",
          "alert": "Please Accept Terms And Conditions",
          "search": "Search for Certificates",
          "selectAlert": "Please select certificate",
          "please": "Please Select",
          "tn": "Tamil Nadu",
          "govt1": "Government of \nTamil Nadu",
          "getGovt": "Get all your Government of \nTamil Nadu Certificates",
          "certificate": "Certificates",
          "edu": "Education",
          "cert": "Certificate",
          "certData": "Certificate Data",
          "hereEdu": "Here is your Education Certificates",
          "hereDiploma": "Here is your Directorate of Medical Education Diploma Certificates",
          "herePharmacy": "Here is your Directorate of Medical Education Diploma in Pharmacy Certificate",
          "hereNursing": "Here is your Directorate of Medical Education Diploma in General Nursing and Midwifery Certificate",
          "hereGovt": "Here is your Government of Tamil Nadu Certificates",
          "stateBoard": "TN State Board Certificates",
          "tnx": "TN State Board X",
          "tnxi": "TN State Board XI",
          "tnxii": "TN State Board XII",
          "record": "No Record Found!",
          "yourcert": "Your Certificates",
          "getTNX": "Get TN State Board X Certificates",
          "getTNXI": "Get TN State Board XI Certificates",
          "getTNXII": "Get TN State Board XII Certificates",
          "tngovt": "Tamil Nadu Education Certificates",
          "tngovtcert": "Tamil Nadu Government Certificates",
          "directorMedicalEdu": "Directorate Of Medical Education",
          "directorMedCert": "Directorate of Medical Education Diploma Certificates",
          "pharmacy": "Diploma in Pharmacy",
          "nursing": "Diploma In General Nursing And Midwifery",
          "getPharmacy":"Get all Directorate of Medical Education Diploma in Pharmacy Certificates",
          "getNursing":"Get all Directorate of Medical Education Diploma in General Nursing and Midwifery Certificates",
          "sharing": "Sharing a Certificate",
          "consent":"I hereby give my consent to e Pettagam to share the certificate and certificate data on request",
          "sharingLink": "Share a Verifiable link",
          "download": "Download as PDF",
          "sharingDoc": "Share a copy of the certificate",
          "entername": "Enter Recipient Name",
          "enteremail": "Enter Recipient Mail Id",
          "validityData": "Validity Date",
          "shareNow": "Share Now",
          "provideConsent": "Kindly provide the consent to share",
          "day": "Day",
          "days": "Days",
          "search1": "Search Your Certificates",
          "noDataFound":"No data found for this certificate, please select any other certificate",
          "login": "Login to get certificates \nby E-Pettagam.",
          "aadhar": "Enter Your Aadhaar Number",
          "enter": "Log In By Using Aadhaar Number",
          "prefetch":"Prefetch all Tamil Nadu \nGovernment certificates, while logging in",
          "consent1": "I hereby consent to provide my Aadhaar Number or One Time Password (OTP) data for Aadhaar based authentication for the purpose of establishing my identity",
          "otp": "Enter OTP received from Aadhar Linked Number",
          "otp1": "Enter OTP from the Registered Mobile Number Linked with your Aadhaar.",
          "resend": "Resend OTP",
          "enteraadhar": "Enter your 12 digit aadhaar here",
          "enterCaptchaplc": "Enter captcha here",
          "validaadhar": "Please Enter Valid Aadhaar Number",
          "validotp": "Please Enter Valid OTP Code",
          "enterCaptcha": "Enter Captcha",
          "disc": "Disclaimer", 
          "discTitle":"Please note that only certificates that follow the format (TN-XXXXXXXXXXXX)  are presently available on the platform. Other certificates should be added shortly.",
          "tngovtCert": "Tamil Nadu Government Certificates",
          "timeRemaining": "Time Remaining",
          "aadharPopup": "Please Enter Aadhaar Number",
          "valdiAadharPopup": "Please Enter Valid Aadhaar Number",
          "consentPopup": "Please Accept Consent to E-Pettagam",
          "nambikkai" : "Powered By Nambikkai Inaiyam",
          "title": "E-Pettagam",
          "title1": "e-Pettagam", 
          "skip": "Skip>>",
          "slide1Title": "Tamil Nadu Education Certificates.",
          "slide1content":"Access your state sanctioned education certificates on demand and share them instantly for verification",
          "next": "Next",
          "chooseLang": "Kindly choose Your Preferred language To use in e-Pettagam.",
          "slide2Title": "Tamil Nadu Government Certificates.",
          "slide2content":"Avail your blockchained state government authorised certificates securely through e-Pettagam and share them instantly for verification.",
          "slide3content":"Get all your Tamil Nadu Government certificates In a single click, and add it in e-Pettagam. Share & Download it using e-pettagam.",
          "started": "Get Started",
          "setting" : "Settings",
          "lang" : "Language",
          "about" : "About Us",
          "delete" : "Delete My Account", 
          "privacy" : "Privacy Policy",
          "help" : "Help & Support",
          "log" : "Logout",
          "deleteAccount" : "Delete My Account",
          "confirmdelete" : "Are you sure you want to delete your account?",
          "ok" : "Ok",
          "cancel" : "Cancel",
          "get": "Get all",
          "certificate1": "Certificates",
          "detail": "Enter Your Details",
          "roll": "Enter Your Roll Number",
          "typeofCert": "Type of Certificate",
          "reg": "Enter Your Reg Number",
          "name": "Enter Your Name",
          "date": "Date of Birth",
          "year": "Enter Year Of Passing",
          "month": "Month of Passing",
          "selectMonth": "Select Certificate Month",
          "terms": "I have agreed to all the Terms and Conditions",
          "toastroll": "Please Enter Your Roll No",
          "toastname": "Please Enter Name",
          "toastdate": "Please Enter DOB",
          "toastyear": "Please Enter Valid Passing Year",
          "toastmonth": "Please Enter Passing Month",
          "toastterms": "Please check Terms And Conditions",
          "valid": "Please enter valid details",
          "invalid": "Invalid Data",
          "home": "Home",
          "settings": "Settings",
          "wallet": "My Documents",
          "fetch1": "Certificates Fetched",
          "shared": "Certificates Shared",
          "doc": "My Documents",
          "tn1": "Education Certificates",
          "govt2": "Government of Tamil Nadu Certificates",
          "stateBoard1": "State Board Certificates",
          "EasySharing":"Easy Sharing of certificates for verification Get all your Tamil Nadu Government certificates In a single click, and add it in e-Pettagam. Share & Download it using e-Pettagam.",
          "AadhaarEnter":"Enter Aadhaar Number",
          "requiredField":"This is a required field",
          "Captcha":"Enter Captcha",
          "Prefetch":"Prefetch all Tamil Nadu Government certificates, while logging in",
          "Authorized":"I authorise and give my consent to ePettagam, for following informed purposes:",
          "Collecting":"collecting, sharing, storing, preserving Information,maintaining records and using the Information for authentication/verification/identification records.",
          "Captcha1":"Enter your captcha",
          "GetAllCert":"Get all your Tamil Nadu Government certificates in a single click.",
          "AddIt":"Add it in e-Pettagam.",
          "ShareandDownload":"Share & Download it using e-Pettagam.",
          "OtpMobile":"Enter OTP from your Aadhaar Registered Mobile Number?",
          "didnrecv":"Didn’t Receive Code?",
          "Higher_Education":"Higher Education",
          "Diploma_Certificate":"Diploma Certificate",
          "School_Education":"School Education",
          "Skills_Certificate":"Skills Certificate",
          "Income":"Income",
          "Nativity":"Nativity",
          "First_Graduation":"First Graduation",
          "OBC_Certificates":"OBC Certificates",
          "wecome":"Welcome ",
          "Add_New":"Add New",
          "Enter_your_DOB":"Enter your DOB",
          "Enter_year":"Enter your passing year",
          "Agreed":"I have agreed to the Terms & Conditions of e-Pettagam to fetch my certificate.",
          "RegistratXXXXX":"Registration Certificate EC ID of 9214XXXXXXXXX has been Fetced Successfully",
          "Course":"Course",
          "Exam":"Exam Month",
          "Exam_Year":"Exam Year",
          "School_Name":"School Name",
          "Certificate_Issued":"Certificate Issued By",
          "Registration_Number":"Registration Number",
          "IConfirm":"I Confirm that I’m Permitting My Certificate to Upload in the Wallet.",
          "My_Wallet":"My Wallet",
          "Certificate_Type":"Certificate Type",
          "Name":"Name",
          "Year_Passing":"Year Of Passing",
          "permanent":"Permanent Register Number",
          "Medium":"Medium Of Instruction",
          "Grade":"Grade Got",
          "tmr":"TMR Code",
          "docReg":"Document Registration No",
          "tmrdate":"TMR Date",
          "Group_Code":"Group Code",
          "Group_Name":"Group Name",
          "Institute":"Institute Name",
          "Subject":"Subject",
          "Theory":"Theory",
          "Practical":"Practical",
          "Internal":"Internal",
          "mark":"Marks Obtained",
          "fetch_cert":"Certificate has been fetched Successfully",
          "DO":"D/O of & S/o of",
          "No":"Certificate No",
          "DI":"Date Of Issue",
          "District":"District",
          "Issuing_Authority":"Issuing Authority",
          "Total":"Total Number of Certificates",
          "sheet":"Mark sheet of Roll No",
          "added":"Added on",
          "Number":"Certificate Number",
          "view":"View / Share",
          "docId":"This Certificate Belong to Document ID",
          "Nambi":"is Verified By Nambikkai Inaiyam.",
          "tran":"Transaction ID",
          "belong":"This Certificate Belong to Roll No",
          "verify":"This Certificate is Verified By Nambikkai Inaiyam.",
          "Roll_No":"Roll No",
          "dob":"DOB",
          "Class":"Class",
          "random":"Random No",
          "Session":"Session",
          "Med":"Medium",
          "Tmar":"Total Marks",
          "Organization":"Organization",
          "exp":"Expiry Date",
          "Father":"Father Name",  
          "Pincode":"Pincode",   
          "serName":"Service Name",   
          "Taluk":"Taluk",  
          "vill":"Vill/Town",
          "NAd":"Aadhaar No",
          "Address":"Address",
          "hereby":"I hereby give my consent to e-Pettagam to share the  certificate and certificate data on request",
          "down":"Click to download",
          "Share":"Share",
          "portal":"Certificate Portal",
          "Email":"Email",
          "cons":"Please give your consent to share the certificate",
          "Certificate_name":"Certificate name",
          "validity":"Share validity",
          "errCap":"Captcha Error!",
          "error":"error",
          "bulkPull":"No data for bulk pull",
          "success":"success",
          "sentOtp":"OTP sent successfully to the registered mobile number",
          "givCons":"Please give your consent",
          "pasting":"Copying and pasting is not allowed!",
          "logSucc":"Login successful",
          "trylog":"Try to login again",
          "warning":"warning",
          "areYou":"Are you sure?",
          "deleSucc":"Your account is successfully deleted",
          "selct":"Select E-Sevai Certificates",
          "eduDisc":"Please note the educational certificates are available for students passing from year 2016 onwards. We are working to bring previous years certificates also soon on this platform. ",
          "RDoc":"Registration Document",
          "BCert":"Birth Certificate",
          "DCERT":"Death Certificate",
          "MCert":"Marriage certificate",
          'seqNum': "Sequence Number",
          "regYr":"Registered Year",
          "Gender":"Gender",
          "Zone":"Zone",
          "District1":"District",
          "Sro":"Sro"
        }
      },
      tamil:{
        translation:{
          "Sro":"Sro",
          "District1":"மாவட்டம்",
          "Zone":"மண்டலம்",
          "Gender":"பாலினம்",
          "regYr":"பதிவு செய்யப்பட்ட ஆண்டு",
          "seqNum":"தொடரிலக்கம்",
          "MCert":"திருமண சான்றிதழ்",
          "DCERT":"இறப்பு சான்றிதழ்",
          "BCert":"பிறப்பு சான்றிதழ்",
          "RDoc": "பதிவு ஆவணம்",
          "eduDisc":"2016 ஆம் ஆண்டு முதல் தேர்ச்சி பெற்ற மாணவர்களுக்கு கல்விச் சான்றிதழ்கள் உள்ளன என்பதை நினைவில் கொள்ளவும். முந்தைய ஆண்டு சான்றிதழ்களையும் இந்த தளத்தில் விரைவில் கொண்டு வர நாங்கள் பணியாற்றி வருகிறோம்.",
          "selct":"E-Sevai சான்றிதழ்களைத் தேர்ந்தெடுக்கவும்",
          "deleSucc":"உங்கள் கணக்கு வெற்றிகரமாக நீக்கப்பட்டது",
          "areYou":"நீ சொல்வது உறுதியா?",
          "warning":"எச்சரிக்கை",
          "trylog":"மீண்டும் உள்நுழைய முயற்சிக்கவும்",
          "logSucc":"உள்நுழைவு வெற்றிகரமாக உள்ளது",
          "pasting":"நகலெடுக்கவும் ஒட்டவும் அனுமதிக்கப்படவில்லை!",
          "givCons":"தயவு செய்து உங்கள் சம்மதத்தை தெரிவிக்கவும்",
          "sentOtp":"பதிவு செய்யப்பட்ட மொபைல் எண்ணுக்கு OTP வெற்றிகரமாக அனுப்பப்பட்டது",
          "success":"வெற்றி",
          "bulkPull":"மொத்த இழுப்பிற்கான தரவு இல்லை",
          "error":"பிழை",
          "errCap":"கேப்ட்சா பிழை!",
          "validity":"பங்கு செல்லுபடியாகும்",
          "Certificate_name":"சான்றிதழ் பெயர்",
          "cons":"சான்றிதழைப் பகிர உங்கள் ஒப்புதலை வழங்கவும்",
          "Email":"மின்னஞ்சல்",
          "portal":"சான்றிதழ் போர்டல்",
          "Share":"பகிர்",
          "down":"பதிவிறக்கம் செய்ய கிளிக் செய்யவும்",
          "hereby":"கோரிக்கையின் பேரில் சான்றிதழ் மற்றும் சான்றிதழ் தரவைப் பகிர்ந்து கொள்ள இ-பெட்டகத்திற்கு எனது ஒப்புதலைத் தருகிறேன்",
          "Address":"முகவரி",
          "NAd":"ஆதார் எண்",
          "vill":"கிராமம்/நகரம்",
          "Taluk":"தாலுகா",
          "serName":"சேவையின் பெயர்",
          "Pincode":"அஞ்சல் குறியீடு",
          "Father":"தந்தையின் பெயர்",
          "exp":"காலாவதி தேதி",
          "Organization":"அமைப்பு",
          "Tmar":"மொத்த மதிப்பெண்கள்",
          "Med":"நடுத்தர",
          "Session":"அமர்வு",
          "random":"ரேண்டம் எண்",
          "Class":"வர்க்கம்",
          "dob":"பிறந்த தேதி",
          "Roll_No":"ரோல் எண்",
          "verify":"இந்தச் சான்றிதழ் நம்பிக்கை இனையத்தால் சரிபார்க்கப்பட்டது.",
          "belong":"இந்தச் சான்றிதழ் ரோல் எண்",
          "tran":"பரிவர்த்தனை ஐடி",
          "Nambi":"நம்பிக்கை இனையத்தால் சரிபார்க்கப்பட்டது.",
          "docId":"இந்த சான்றிதழ் ஆவண ஐடிக்கு சொந்தமானது",
          "view":"பார்க்க / பகிரவும்",
          "Number":"சான்றிதழ் எண்",
          "added":"சேர்க்கப்பட்டது",
          "sheet":"ரோல் எண் குறித்தாள்",
          "Total":"சான்றிதழ்களின் மொத்த எண்ணிக்கை",
          "Issuing_Authority":"வழங்குதல் ஆணையம்",
          "District":"மாவட்டம்",
          "DI":"வெளியீட்டு தேதி",
          "No":"சான்றிதழ் எண்",
          "DO":"D/O இன் & S/o",
          "fetch_cert":"சான்றிதழ் வெற்றிகரமாகப் பெறப்பட்டது",
          "mark":"பெற்ற மதிப்பெண்கள்",
          "Internal":"உள்",
          "Practical":"நடைமுறை",
          "Theory":"கோட்பாடு",
          "Subject":"பொருள்",
          "Institute":"நிறுவனத்தின் பெயர்",
          "Group_Name":"குழு பெயர்",
          "Group_Code":"குழு குறியீடு",
          "tmrdate":"TMR தேதி",
          "docReg":"ஆவணப் பதிவு எண்",
          "tmr":"TMR குறியீடு",
          "Grade":"கிரேடு கிடைத்தது",
          "Medium":"பயிற்றுவிப்பு ஊடகம்",
          "permanent":"நிரந்தரப் பதிவு எண்",
          "Year_Passing":"கடந்த ஆண்டு",
          "Name":"பெயர்",
          "Certificate_Type":"சான்றிதழ் வகை",
          "My_Wallet":"என் பணப்பை",
          "IConfirm":"எனது சான்றிதழை வாலட்டில் பதிவேற்ற நான் அனுமதிப்பதாக உறுதியளிக்கிறேன்.",
          "Registration_Number":"பதிவு எண்",
          "Certificate_Issued":"சான்றிதழ் வழங்கியவர்",
          "School_Name":"பள்ளி பெயர்",
          "Exam_Year":"தேர்வு ஆண்டு",
          "Exam":"தேர்வு மாதம்",
          "Course":"பாடநெறி",
          "RegistratXXXXX":"9214XXXXXXXXX இன் பதிவுச் சான்றிதழ் EC ஐடி வெற்றிகரமாகப் பெறப்பட்டது",
          "Agreed":"எனது சான்றிதழைப் பெற இ-பெட்டகத்தின் விதிமுறைகள் மற்றும் நிபந்தனைகளை நான் ஒப்புக்கொண்டேன்.",
          "Enter_year":"கடந்த ஆண்டை உள்ளிடவும்",
          "Enter_your_DOB":"உங்கள் DOB ஐ உள்ளிடவும்",
          "Add_New":"புதிதாக சேர்க்கவும்",
          "wecome":"பயனர் வரவேற்கிறோம்!",
          "OBC_Certificates":"OBC சான்றிதழ்கள்",
          "First_Graduation":"முதல் பட்டப்படிப்பு",
          "Nativity":"நேட்டிவிட்டி",
          "Income":"வருமானம்",          
          "Skills_Certificate":"திறன் சான்றிதழ்",
          "School_Education":"பள்ளிக் கல்வி",
          "Diploma_Certificate":"டிப்ளமோ சான்றிதழ்",
          "Higher_Education":"உயர் கல்வி",
          "didnrecv":"குறியீட்டைப் பெறவில்லையா?",
          "OtpMobile":"உங்கள் ஆதார் பதிவு செய்யப்பட்ட மொபைல் எண்ணிலிருந்து OTP ஐ உள்ளிடவா?",
          "ShareandDownload":"e-Pettagam ஐப் பயன்படுத்தி அதைப் பகிரவும் & பதிவிறக்கவும்.",
          "AddIt":"இ-பெட்டகத்தில் சேர்க்கவும்.",
          "GetAllCert":"உங்களின் அனைத்து தமிழ்நாடு அரசு சான்றிதழ்களையும் ஒரே கிளிக்கில் பெறுங்கள்.",
          "Captcha1":"உங்கள் கேப்ட்சாவை உள்ளிடவும்",
          "Collecting": "தகவல்களை சேகரித்தல், பகிர்தல், சேமித்தல், பாதுகாத்தல், பதிவுகளை பராமரித்தல் மற்றும் அங்கீகாரம்/சரிபார்ப்பு/அடையாளப் பதிவுகளுக்கு தகவலைப் பயன்படுத்துதல்.",
          "Authorized":"பின்வரும் தகவலறிந்த நோக்கங்களுக்காக நான் ePettagam ஐ அங்கீகரித்து எனது ஒப்புதலை வழங்குகிறேன்:",
          "Prefetch":"உள்நுழையும்போது, ​​அனைத்து தமிழ்நாடு அரசு சான்றிதழ்களையும் முன்கூட்டியே பெறவும்",
          "Captcha":"கேப்ட்சாவை உள்ளிடவும்",
          "requiredField":"இது தேவையான புலம்",
          "AadhaarEnter":"ஆதார் எண்ணை உள்ளிடவும்",
          "submit": "சமர்ப்பிக்க",
          "share": "இப்போது பகிர்ந்து கொள்ளுங்கள்",  
          "add": "நம்பிக்கை இனையம் பற்றிய உங்கள் தரவைப் பாதுகாக்கவும்",   
          "fetch": "இ பெட்டகத்தைப் பயன்படுத்தி உங்கள் சான்றிதழ்களைப் பெறுங்கள்",
          "service": "எங்கள் சேவைகள்",
          "service1": "எங்கள் சேவைகள்",
          "education": "கல்வி சான்றிதழ்கள்",
          "govt": "இ-சேவை சான்றிதழ்கள்",
          "sync": "டிஜி லாக்கருடன் ஒத்திசைக்கவும்",
          "esevai": "உங்கள் இ-சேவை சான்றிதழைத் தேர்ந்தெடுக்கவும்",
          "select": "உங்கள் சான்றிதழைத் தேர்ந்தெடுக்கவும்",
          "alert": "விதிமுறைகள் மற்றும் நிபந்தனைகளை ஏற்கவும்",
          "search": "சான்றிதழ்களைத் தேடுங்கள்",
          "selectAlert": "சான்றிதழைத் தேர்ந்தெடுக்கவும்",
          "please": "தயவு செய்து தேர்வு செய்யவும்",
          "tn": "தமிழ்நாடு",
          "govt1": "Government of \nTamil Nadu",
          "getGovt": "தமிழக அரசின் அனைத்து \nசான்றிதழ்களையும் \nபெறுங்கள்",
          "certificate": "சான்றிதழ்கள்",
          "edu": "கல்வி",
          "cert": "சான்றிதழ்",
          "certData": "சான்றிதழ் தரவு",
          "hereEdu": "உங்கள் கல்விச் சான்றிதழ்களைப் பெறுங்கள்",
          "hereDiploma": "உங்கள் மருத்துவக் கல்வி டிப்ளமோ சான்றிதழ்கள் இயக்குநரகம் இதோ",
          "herePharmacy": "இதோ உங்களது மருத்துவக் கல்வி இயக்குனரகம் பார்மசியில் டிப்ளமோ சான்றிதழ்",
          "hereNursing": "இதோ உங்களது மருத்துவக் கல்வி இயக்குநரகம் பொது நர்சிங் மற்றும் மருத்துவச்சி சான்றிதழ்களில் டிப்ளமோ",
          "hereGovt": "உங்கள் தமிழ்நாடு அரசின் சான்றிதழ்களைப் பெறுங்கள்",
          "stateBoard": "TN மாநில கல்வி வாரிய சான்றிதழ்கள்",
          "tnx": "வகுப்பு 10 TN மாநில வாரிய சான்றிதழ்கள்",
          "tnxi": "வகுப்பு 11 TN மாநில வாரிய சான்றிதழ்கள்",
          "tnxii": "வகுப்பு 12 TN மாநில வாரிய சான்றிதழ்கள்",
          "record": "பதிவு எதுவும் கிடைக்கவில்லை",
          "yourcert": "உங்கள் சான்றிதழ்கள்",
          "getTNX": "10 ஆம் வகுப்பு TN மாநில கல்வி வாரிய சான்றிதழ்களைப் பெறுங்கள்",
          "getTNXI": "11 ஆம் வகுப்பு TN மாநில கல்வி வாரிய சான்றிதழ்களைப் பெறுங்கள்",
          "getTNXII": "12 ஆம் வகுப்பு TN மாநில கல்வி வாரிய சான்றிதழ்களைப் பெறுங்கள்",
          "tngovt": "தமிழ்நாடு கல்விச் சான்றிதழ்கள்",
          "tngovtcert": "தமிழ்நாடு அரசு சான்றிதழ்கள்",
          "directorMedicalEdu": "மருத்துவக் கல்வி இயக்குநரகம்",
          "directorMedCert": "மருத்துவக் கல்வி டிப்ளமோ சான்றிதழ்கள் இயக்குநரகம்",
          "pharmacy": "மருந்தகத்தில் டிப்ளமோ",
          "nursing": "பொது நர்சிங் மற்றும் மருத்துவச்சி டிப்ளமோ",
          "getPharmacy":"அனைத்து மருத்துவக் கல்வி இயக்குநரகத்தின் பார்மசியில் டிப்ளமோ சான்றிதழ்களைப் பெறுங்கள்",
          "getNursing":"அனைத்து மருத்துவ கல்வி இயக்குனரக டிப்ளமோ நர்சிங் மற்றும் மருத்துவச்சி சான்றிதழ்களைப் பெறுங்கள்",
          "sharing": "ஒரு சான்றிதழைப் பகிர்தல்",
          "consent":"கோரிக்கையின் பேரில் சான்றிதழ் மற்றும் சான்றிதழ் தரவைப் பகிர்ந்து கொள்ள இ பெட்டகம் நிறுவனத்திற்கு இதன் மூலம் எனது ஒப்புதலை அளிக்கிறேன்",
          "sharingLink": "சரிபார்க்கக்கூடிய இணைப்பைப் பகிரவும்",
          "download": "PDF ஆக பதிவிறக்கவும்",
          "sharingDoc": "சான்றிதழின் நகலைப் பகிரவும்",
          "entername": "பெறுநரின் பெயரை உள்ளிடவும்",
          "enteremail": "பெறுநரின் அஞ்சல் ஐடியை உள்ளிடவும்",
          "validityData": "செல்லுபடியாகும் தேதி",
          "shareNow": "இப்போது பகிரவும்",
          "provideConsent": "பகிர்வதற்கான ஒப்புதலை தயவுசெய்து வழங்கவும்",
          "day": "நாள்",
          "days": "நாட்களில்",
          "search1": "உங்கள் சான்றிதழ்களைத் தேடுங்கள்",
          "noDataFound":"இந்த சான்றிதழுக்கான தரவு எதுவும் இல்லை, தயவுசெய்து வேறு ஏதேனும் சான்றிதழைத் தேர்ந்தெடுக்கவும்",
          "login": "இ-பெட்டகம் மூலம் சான்றிதழ்களைப் பெற உள்நுழையவும்",
          "aadhar": "உங்கள் ஆதார் எண்ணை உள்ளிடவும்",
          "enter": "ஆதார் எண்ணைப் பயன்படுத்தி உள்நுழையவும்",
          "prefetch":"உள்நுழையும்போது அனைத்து தமிழ்நாடு அரசு சான்றிதழ்களையும் முன்கூட்டியே பெறவும்",
          "consent1": "எனது அடையாளத்தை நிறுவும் நோக்கத்திற்காக ஆதார் அடிப்படையிலான அங்கீகாரத்திற்கான எனது ஆதார் எண் அல்லது ஒரு முறை பின் (OTP) தரவை வழங்க",
          "otp": "உங்கள் ஆதாருடன் இணைக்கப்பட்ட பதிவு செய்யப்பட்ட மொபைல் எண்ணிலிருந்து OTP ஐ உள்ளிடவும்.",
          "otp1": "Enter OTP from the Registered Mobile Number Linked with your Aadhaar.",
          "resend": "OTP ஐ மீண்டும் அனுப்பவும்",
          "enteraadhar": "உங்கள் 12 இலக்க ஆதாரை இங்கே உள்ளிடவும்",
          "enterCaptchaplc": "இங்கே கேப்ட்சாவை உள்ளிடவும்",
          "validaadhar": "சரியான ஆதார் எண்ணை உள்ளிடவும்",
          "validotp": "சரியான OTP குறியீட்டை உள்ளிடவும்",
          "enterCaptcha": "இங்கே கேப்ட்சாவை உள்ளிடவும்",
          "disc": "உங்கள் கவனத்திற்கு",
          "discTitle":"(TN-XXXXXXXXXXXX) வடிவமைப்பைப் பின்பற்றும் சான்றிதழ்கள் மட்டுமே தற்போது தளத்தில் கிடைக்கின்றன என்பதை நினைவில் கொள்ளவும்",
          "tngovtCert": "தமிழ்நாடு அரசு சான்றிதழ்கள்",
          "timeRemaining": "மீதியுள்ள நேரம்",
          "aadharPopup": "ஆதார் எண்ணை உள்ளிடவும்",
          "valdiAadharPopup": "சரியான ஆதார் எண்ணை உள்ளிடவும்",
          "consentPopup": "'E-Pettagam க்கு சம்மதத்தை ஏற்கவும்",
          "nambikkai" : "நம்பிக்கை இனையத்தால் இயக்கப்படுகிறது",
          "title": "பெட்டகம்",
          "title1": "பெட்டகம்",
          "skip": "தவிர்க்கவும்>>",
          "slide1Title": "தமிழ்நாடு கல்விச் சான்றிதழ்கள்.",
          "slide1content":"தேவைக்கேற்ப உங்கள் மாநில அனுமதி பெற்ற கல்விச் சான்றிதழ்களை அணுகி, அவற்றை சரிபார்ப்பதற்காக உடனடியாகப் பகிரவும்",
          "next": "அடுத்தது",
          "chooseLang": "E-Pettagam இல் பயன்படுத்த உங்களுக்கு விருப்பமான மொழியை தேர்வு செய்யவும்.",
          "slide2Title": "தமிழ்நாடு அரசு சான்றிதழ்கள்.",
          "slide2content":"உங்கள் பிளாக்செயின் செய்யப்பட்ட மாநில அரசு அங்கீகரிக்கப்பட்ட சான்றிதழ்களை இ-பெட்டகம் மூலம் பாதுகாப்பாகப் பெற்று, அவற்றை சரிபார்ப்பதற்காக உடனடியாகப் பகிரவும்.",
          "slide3content":"உங்களின் அனைத்து தமிழ்நாடு அரசு சான்றிதழ்களையும் ஒரே கிளிக்கில் பெற்று, இ-பெட்டகத்தில் சேர்க்கவும். இ-பெட்டகத்தைப் பயன்படுத்தி அதைப் பகிரவும் & பதிவிறக்கவும்.",
          "started": "தொடங்குங்கள்",
          "setting" : "அமைப்புகள்",
          "lang" : "மொழி",
          "about" : "எங்களைப் பற்றிய விவரங்கள்",
          "delete" : "எனது பயனர் கணக்கை நீக்கவும்", 
          "privacy" : "தனியுரிமைக் கொள்கை",
          "help" : "உதவி மற்றும் ஆதரவு",
          "log" : "வெளியேறு",
          "deleteAccount" : "எனது கணக்கை நீக்கு",
          "confirmdelete" : "உங்கள் கணக்கை நிச்சயமாக நீக்க விரும்புகிறீர்களா?",
          "ok" : "சரி",
          "cancel" : "ரத்து செய்",
          "get": "பெறு",
          "certificate1": "சான்றிதழ்கள்",
          "detail": "உங்கள் விவரங்களை உள்ளிடவும்",
          "roll": "உங்கள் ரோல் எண்ணை உள்ளிடவும்",
          "typeofCert": "சான்றிதழ் வகை",
          "reg": "உங்கள் REG எண்ணை உள்ளிடவும்",
          "name": "உங்கள் பெயரை உள்ளிடவும்",
          "date": "பிறந்த தேதி",
          "year": "தேர்ச்சி ஆண்டை உள்ளிடவும்",
          "month": "கடந்து செல்லும் மாதம்",
          "selectMonth": "சான்றிதழ் மாதத்தைத் தேர்ந்தெடுக்கவும்",
          "terms": "நான் அனைத்து விதிமுறைகளையும் நிபந்தனைகளையும் ஒப்புக்கொண்டேன்",
          "toastroll": "தயவுசெய்து உங்கள் ரோல் எண்ணை உள்ளிடவும்",
          "toastname": "தயவுசெய்து பெயரை உள்ளிடவும்",
          "toastdate": "தயவுசெய்து DOB ஐ உள்ளிடவும்",
          "toastyear": "செல்லுபடியாகும் கடந்த ஆண்டை உள்ளிடவும்",
          "toastmonth": "கடந்து செல்லும் மாதத்தை உள்ளிடவும்",
          "toastterms": "விதிமுறை மற்றும் நிபந்தனைகளை சரிபார்க்கவும்",
          "valid": "சரியான விவரங்களை உள்ளிடவும்",
          "invalid": "தவறான தரவு",
          "home": "முதன்மை திரை",
          "settings": "அமைப்புகள்",
          "wallet": "எனது ஆவணம்",
          "fetch1": "பெறப்பட்ட சான்றிதழ்களின் எண்ணிக்கை",
          "shared": "பகிரப்பட்ட சான்றிதழ்களின் எண்ணிக்கை",
          "doc": "எனது ஆவணங்கள்",
          "tn1": "தமிழ்நாடு கல்விச் சான்றிதழ்கள்",
          "govt2": "தமிழக அரசால் அங்கீகரிக்கப்பட்ட \nஆவணங்கள்",
          "stateBoard1": "மாநில வாரிய சான்றிதழ்கள்",
          "EasySharing":"சரிபார்ப்புக்கான சான்றிதழ்களை எளிதாகப் பகிர்தல் உங்களின் அனைத்து தமிழ்நாடு அரசு சான்றிதழ்களையும் பெறுங்கள்  ஒரே கிளிக்கில், அதை இ-பெட்டகத்தில் சேர்க்கவும். பகிர் & e-Pettagam ஐப் பயன்படுத்தி பதிவிறக்கவும்."
        }
      }
    },
    lng: sessionStorage.getItem('language') === null ? "en" : sessionStorage.getItem('language'), // if you're using a language detector, do not define the lng option
    fallbackLng: sessionStorage.getItem('language') === null ? "en" : sessionStorage.getItem('language'),

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App() {
  const { t } = useTranslation();

  // return <h2>{t('Welcome to React')}</h2>;
}

// append app to dom
const root = createRoot(document.getElementById('root'));
root.render(
  <App />
);